// MIXINS

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-horizontal {
  @media (max-height: 500px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-down {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-tablet-desktop-medium-down {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

/* FONTS */
@font-face {
  font-family: "Nimbus";
  src: local("Nimbus"), url(./assets/fonts/NimbusSansDOT-LighExte.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Orbitron";
  src: local("Orbitron"), url(./assets/fonts/Orbitron-VariableFont_wght.woff) format("woff");
  font-display: swap;
}

.nimbus {
  font-family: "Nimbus";
}

.orbitron {
  font-family: "Orbitron";
}

.uppercase {
  text-transform: uppercase;
}

/* GENERAL */
.App {
  text-align: center;
  width: 100vw;
  overflow: hidden;
}

.App-logo {
  height: auto;
  width: 320px;
  // margin-left: -30px;

  @include for-tablet-desktop-medium-down {
  }
}

.backgroundVideo {
  video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
  }
}

.homeWrapper {
  display: flex;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  .mobileNavContainer {
    display: none;
  }
  .mainNav {
    align-items: center;
    li .active {
      &:before,
      &:after {
        display: none;
      }
    }
  }

  @include for-tablet-desktop-medium-down() {
    height: auto;
  }
  @include for-phone-only() {
    margin-top: 0vh;
  }

  .footerNav {
    position: absolute;

    @include for-horizontal() {
      margin-bottom: -250px;
    }
  }

  &.withCountdown {
    flex-flow: column;
    justify-content: center;

    @include for-tablet-desktop-medium-down() {
      justify-content: center;
      margin-top: 5vh;
      .mainNav.comingSoon {
        justify-content: center;
      }
    }
  }
}

.sep {
  width: 5px;
  height: 5px;
}

/*NAV */

// mobile nav ****************************************************************
.mobileNav {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    font-size: 30px;
    margin-bottom: 30px;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  right: 36px;
  p {
    color: #d8d8d8;
    text-decoration: none;
    font-size: 30px;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #d8d8d8;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #d8d8d8;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #000;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  .active {
    color: #686868 !important;
    pointer-events: none;
    &:before {
      margin-bottom: 12px;
      content: " ";
      height: 5px;
      width: 5px;
      position: absolute;
      display: block;
      background-color: #686868;

      left: 0;
      bottom: 0;
      margin-left: -16px;
    }
    &:after {
      margin-bottom: 12px;
      content: " ";
      height: 5px;
      width: 5px;
      position: absolute;
      display: block;
      background-color: #686868;

      right: 0;
      bottom: 0;
      margin-right: -16px;
    }
  }

  a {
    color: #d8d8d8;
    font-family: "orbitron";
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

// end of mobile nav ****************************************************************

.aboutNav-container {
  // width: 60vw;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 5vh;

  img {
    width: 80%;
  }
}

.aboutNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;

  @include for-phone-only() {
    flex-flow: column;
    justify-content: center;
  }

  li {
    list-style: none;
    color: #d8d8d8;
    font-size: 30px;
    width: 200px;
    text-align: center;

    @include for-phone-only() {
      margin-bottom: 1rem;
    }

    a {
      color: #d8d8d8;
      text-decoration: none;
      font-size: 30px;
      .active {
        color: #a5a5a5;
        pointer-events: none;
      }
    }
  }
}
.homeNav {
  @include for-tablet-desktop-medium-down {
    flex-flow: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .pull-right {
    display: block !important;
    @include for-desktop-up {
      margin-right: -10rem !important;
    }

    @include for-tablet-landscape-up {
      margin-right: -5rem;
    }

    @include for-tablet-desktop-medium-down {
      margin-right: 0 !important;
      position: relative !important;
      left: auto !important;
    }

    @include for-phone-only {
      margin-right: 0 !important;
      position: relative !important;
      left: auto !important;
    }
  }
}
.mainNav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;

  &.comingSoon {
    justify-content: space-evenly;
  }

  @include for-tablet-desktop-medium-down {
    flex-flow: row-reverse;
    justify-content: center;
    align-items: flex-start;
  }

  .pull-left {
    @include for-desktop-up {
      margin-left: -10rem !important;
    }

    @include for-tablet-landscape-up {
      margin-left: -5rem;
    }

    @include for-tablet-desktop-medium-down {
      margin-left: 0;
    }

    @include for-phone-only {
      margin-left: 0;
    }
  }

  .pull-right {
    display: none;
    @include for-desktop-up {
      margin-right: -10rem !important;
    }

    @include for-tablet-landscape-up {
      margin-right: -5rem;
    }
    @include for-tablet-portrait-up {
      display: block;
    }
    @include for-tablet-desktop-medium-down {
      position: absolute;
      left: 18px;
    }

    @include for-phone-only {
      position: absolute;
      left: 18px;
    }
  }

  li {
    list-style: none;
    color: #d8d8d8;
    font-size: 30px;
    margin: 0 10px;
    position: relative;
    &:hover {
      &:not(.App-logo-container) {
        &:before {
          margin-bottom: 12px;
          content: " ";
          height: 5px;
          width: 5px;
          position: absolute;
          display: block;
          background-color: #d8d8d8;

          left: 0;
          bottom: 0;
          margin-left: -16px;
        }
        &:after {
          margin-bottom: 12px;
          content: " ";
          height: 5px;
          width: 5px;
          position: absolute;
          display: block;
          background-color: #d8d8d8;
          right: 0;
          bottom: 0;
          margin-right: -16px;
        }
      }
    }
    .active {
      color: #686868 !important;
      pointer-events: none;
      &:before {
        margin-bottom: 12px;
        content: " ";
        height: 5px;
        width: 5px;
        position: absolute;
        display: block;
        background-color: #686868;
        z-index: 9999;
        left: 0;
        bottom: 0;
        margin-left: -16px;
      }
      &:after {
        margin-bottom: 12px;
        content: " ";
        height: 5px;
        width: 5px;
        position: absolute;
        display: block;
        background-color: #686868;
        z-index: 9999;
        right: 0;
        bottom: 0;
        margin-right: -16px;
      }
    }
    a {
      color: #d8d8d8;
      text-decoration: none;
      font-size: 30px;
    }
  }
}

// COUNTDOWN
.countdown {
  color: #d8d8d8;
  font-family: "Orbitron";
  font-size: 25px;
  padding: 0 2rem;
}

//FOOTER NAV

.footerNav {
  bottom: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    @include for-tablet-landscape-down {
      width: 25vw;
    }

    li {
      list-style: none;
      padding: 0 20px;

      a {
        color: #d8d8d8;
        text-decoration: none;
        font-size: 15px;
      }
    }
  }
}

.socialMedia-icons {
  height: 20px;
  transform: scale(1.15);
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

.emailSignup {
  display: flex;
  flex-flow: row;
  margin: 0;
  padding: 0;
  justify-content: center;

  button,
  input {
    font-family: "Orbitron";
  }

  button {
    border-radius: 0px 4px 4px 0 !important;
    border: 3px solid #d8d8d8;
    // height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 16px;
    cursor: pointer;
    margin: 0 !important;

    &:disabled {
      background-color: #d8d8d8cf;
      text-decoration: line-through;
      cursor: auto;
      // height: 30px;
      margin: 0 !important;
    }
  }
  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  input#email {
    background-color: #d8d8d84f;
    box-shadow: none;
    border: 3px solid #d8d8d84a;
    border-radius: 4px 0 0 4px !important;
    height: 30px;
    margin: 0 !important;
  }
}

//PAGES
.aboutRed {
  background-image: url(../src/assets/backgrounds/About-background.jpg);
}
.aboutBlue {
  background-image: url(../src/assets/backgrounds/About-background-BLUE.jpg);
}

.blackBg3D{
  background-image: url(../src/assets/backgrounds/Website_1.png);

}
.about {
  background-size: cover;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  align-items: center;
  // padding-bottom: 10vh;
  background-attachment: fixed;
  .mainNav {
    position: fixed;
    width: 97vw;
  }
  .container {
    margin: 35vh 10vw 0 10vw;

    p {
      color: #d8d8d8;
      font-size: 20px;
      text-align: center;
    }
  }
}

.streamRed {
  background-image: url(../src/assets/backgrounds/Stream-background.jpg);
}
.streamBlue {
  background-image: url(../src/assets/backgrounds/Stream-background-BLUE.jpg);
}
.stream {
  background-size: cover;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: 10vh;
  background-attachment: fixed;
  .mainNav {
    position: fixed;
    width: 97vw;
  }
  .streamContainer {
    @include for-tablet-landscape-down {
      margin-top: 35vh;
    }
  }
  .streamItemContainer {
    display: flex;
    color: #d8d8d8;
    margin-top: 40vh;
    overflow: scroll;
    text-align: center;
    padding: 30px;
    @include for-tablet-landscape-down {
      flex-flow: column;
      justify-content: center;
      align-items: center;
      margin-top: 0;
    }

    a {
      color: #d8d8d8;
      text-decoration: none;
    }
    .artwork {
      width: calc(50vw - 30px);
      @include for-tablet-landscape-down {
        width: calc(70vw - 30px);
      }
      img {
        width: 100%;
      }
    }

    .artInfo {
      width: calc(50vw - 30px);
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      @include for-tablet-landscape-down {
        width: calc(90vw - 30px);
      }
      .title {
        width: 100%;
        h2 {
          font-size: 70px;
          font-weight: 100;
          margin: 0;
          @include for-tablet-landscape-down {
            font-size: 55px;
          }
        }
        div {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: 200px;
          margin: auto;
          span {
            width: calc(200px / 2 - 5px);
          }
        }
      }
      .links {
        ul {
          padding: 0;
          @include for-tablet-landscape-down {
            margin: 60px 0;
          }
          li {
            text-transform: uppercase;
            font-size: 30px;
            margin-bottom: 30px;
            list-style: none;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            @include for-tablet-landscape-down {
              margin-bottom: 0;
            }
            a {
              padding: 0 10px;
            }
          }
        }
        div {
          margin-top: 100px;
          @include for-tablet-landscape-down {
            display: none;
          }
        }
      }
    }
  }
}

.watch {
  background-image: url(../src/assets/backgrounds/Stream-background.jpg);
  background-size: cover;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-attachment: fixed;
  .mainNav {
    position: fixed;
    width: 97vw;
    opacity: 0.9;
    mix-blend-mode: luminosity;
    transition: all 0.1s ease;
    &:hover {
      opacity: 1;
      mix-blend-mode: normal;
    }
    @include for-tablet-desktop-medium-down {
      opacity: 1;
      mix-blend-mode: normal;
    }
  }

  .videos {
    iframe {
      width: 100vw !important;
      height: 100vh;
      padding: 0;
      margin: 0;
      background-color: black;
      margin-bottom: -5px;
    }
  }
}
@include for-phone-only(){
  .landing{
    justify-content: start !important;
  }

  .App-logo{
    width: 300px !important;
  }
}
.landing {
  display: flex;
  flex-flow: column;
  // width: 100vw;
  min-height: 100vh;
  justify-content: space-around;
  align-items: center;
  background-color: black;
  background-size: cover;

  .App-logo{
    margin-top:50px;
    margin-bottom:50px;

  }

  @include for-phone-only(){
    .wrapper {
      width: 95% !important;
    }

    .App-logo{
      margin-bottom:40px !important;
  
    }

 

    .footerNav {

      margin-top:30px !important;
    }
  }


  .wrapper {
    
    width: 80%;
    iframe {
      aspect-ratio: 16/9;
      width: 100%;
    }
  }
  // img {
  // margin-bottom: 50px;
  // }
  .footerNav {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 100vw;
    margin-top:50px;
    margin-bottom: 25px;
  }
}
